import React from 'react'
import { Navbar, Nav ,Container } from 'react-bootstrap'
import {Link } from 'react-router-dom'
const Menu = () => {
    return (
        <>
       <Navbar bg="dark" variant="dark" expand="lg"  className="position-fixed w-100 navs">
      <Container>
        <Navbar.Brand href="#home"><div className="banner-head-1 text-primary">Restaurant</div></Navbar.Brand>
        <Navbar.Toggle/>
        
        <Navbar.Collapse >
          <Nav className="ms-auto">
          <Nav.Link as={Link} to='/' className='nav-item'>Home</Nav.Link>
          <Nav.Link as={Link} to='/services' className='nav-item'>Services</Nav.Link>   
            <Nav.Link as={Link} to='/about' className='nav-item'>About</Nav.Link>           
           <Nav.Link as={Link} to='/contact' className='nav-item'>Contact</Nav.Link>           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            
        </>
        
    )
}

export default Menu
