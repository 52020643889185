import React from 'react'
import { useCart } from 'react-use-cart'

const Cards = (props) => {
    const {addItem} = useCart();
    return (
        <>
    
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-4 mt-4">
           <div className="card  shadow c-img">
               <img src={props.img} alt="" className="card-img-top img-fluid "/>
               <div className="card-body">
                   <div className="d-flex align-items-center justify-content-between">
                   <h5 className="card-title ">
                      {props.title}
                   </h5>
    <h4>${props.price}</h4>
                   </div>
                   
    <p className="card-text">{props.desc}</p>
    <button className="btn btn-outline-success"onClick={()=>addItem(props.item)}>
        Add Cart
    </button>
               </div>


           </div>
      
    </div>
        </>
    )
}

export default Cards
