import React from 'react'
import './Pages.css'
import Chef from '../assest/images/chef-1.jpg'
import ChefTwo from '../assest/images/chef-2.jpg'
import Footer from './Footer'

const Services = () => {
    return (
        <>
            <section className="service ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 b-img ">
                        <img src={Chef} alt="" className="rounded"/>
                        </div>
                        <div className="col-md-6 col-lg-6 mt-5 px-5">
                            <h2 className="fw-6">
                            BEST COOKS MAKE <br/>
                            <span className="text-primary text-over">HEALTHY & TASTY</span> FOODS
                            </h2>
                            <p className="fs-5 mt-5">
                            And restaurants and food businesses are cashing in on this. Here's our curated list of social content ideas to add a unique flavor to your brand recipe. Feel free to cycle through these ideas to have your customers keep coming back for more!
                            With finger-licking recipes from chefs to mouth-watering images of perfectly plated dishes,</p>
                        </div>
                    </div>
                </div>

           </section>
           <section className="chef-spl">
               <div className="container">
                   <div className="row">
                   <div className="col-md-6 col-lg-6">
                       <h2 className="fs-1 fw-1">Best Cooking <span className="text-primary text-over">our <br/>Chef Special</span></h2>
                       <p className="fs-5 mt-5">
                       The specials on your menu can be a great source of content for your users. You can create specials around festivals, a local event, time of the year, or just everyday specials. Make sure to keep your customers keep coming back for more! The time-bound nature of these items can create FOMO and encourage more visits.
                       </p>
                       <p className="fs-5 mt-5">
                       Does your restaurant offer vegan-friendly options? Or maybe you’re a baker that provides lactose-free products on your menu. Your restaurant may offer that something special which attracts a certain set of foodies. Don't forget to call this out - menus like vegan, gluten-free, organic ingredients and fresh catch are great ideas to grab those customer niches looking to try something new.
                       </p>
                   </div>
                       <div className="col-md-6 col-lg-6 chef-2 ">
                           <img src={ChefTwo} alt="" className="rounded"/>
                       </div>
                      
                   </div>
               </div>

               

           </section>
           <Footer/>
        </>
    )
}

export default Services
