import React from 'react'
import { useCart } from 'react-use-cart'
import './cart.css';
const Cart = () => {
    const{
        isEmpty,
        totalItems,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
    }=useCart();
    if(isEmpty) return <h3 className="text-title fw-bold text-center mt-4 py-5"> Your Cart is Empty </h3>
    return (
        <>
<div className="container-fluid">
    <div className="row justify-content-center ">
       <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 py-4">
      
           <p className="text-center py-4 fw-bold fs-4 mt-5"> Total-Items({totalItems})</p>
           <table className="table table-light table-hover m.0">
               <tbody className="">
                   {items.map((item,index)=>{
                       return(
                           <tr key={index} className="align-middle">
                               <td><img src={item.img} className="img-cart" alt=""/></td>
                               <td className="fw-bold">{item.title}</td>
                       <td>${item.price}</td>
                       <td>Quantity:{item.quantity}</td>
                          <td>
                              <div className="mob-view">
                              <button onClick={()=>updateItemQuantity(item.id,item.quantity -1)} className="btn btn-outline-dark ms-1">-</button>
                              <button onClick={()=>updateItemQuantity(item.id,item.quantity +1)} className="btn btn-outline-dark ms-1">+</button>
                              <button onClick={()=>removeItem(item.id)} className="btn btn-outline-danger ms-5"><i class="fa-solid fa-trash"></i></button>
                              </div>
                              </td>  
                
                           </tr>
                       )
                   })}
               </tbody>
           </table>
           <div className="d-flex justify-content-between py-3">
               <button onClick={()=>emptyCart()} className="btn btn-outline-danger">Clear All</button>
                <h3>Total Price:${cartTotal}</h3>
</div>
       </div>
       
    </div>
    
</div>
            
        </>

    )
}

export default Cart
