import React from 'react'

const Contact = () => {
    return (
        <>
          <section className="mt-5 contact">
            <div className="container">
              <div className="row ">
              <div className="col-12 ">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.123132971364!2d80.21222061430487!3d13.027829917168088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267b48db904c5%3A0x8a3fa6adfa50075!2sRestaurants!5e0!3m2!1sen!2sin!4v1670138461097!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              </div>
            </div>
          </section>
          <section className="contact-list">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 d-flex  align-items-center justify-content-center">
                  <span className="icons icon-1"><i class="fa-solid fa-phone"></i></span>
                  <span className="px-2 tesxt"><h5>123 456 7890</h5></span>

                </div>
               
               
                <div className="col-md-4 col-lg-4 d-flex  align-items-center justify-content-center">
                  <span className="icons icon-2"><i class="fa-solid fa-envelope"></i></span>
                  <span className="px-2 tesxt"><h5>newresturant.gmail.com</h5></span>

                </div>
                <div className="col-md-4 col-lg-4 d-flex align-items-center justify-content-center">
                  <span className="icons icon-3"><i class="fa-solid fa-address-book"></i></span>
                  <span className="px-2 tesxt"><p>31, VSM Garden St, Ragavan Colony, Saidapet, Chennai, Tamil Nadu 600098</p></span>

                </div>
               
              </div>

            </div>

          </section>
          <footer className=" text-white text-center p-3">copyright 	&#169; <a href="">newrestaurant.gmail.com</a> </footer>
        </>
    )
}

export default Contact
