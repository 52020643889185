import imgOne from './assest/images/cart/chicken.jpg'
import imgOne1 from './assest/images/chicken/chetti.webp'
import imgOne2 from './assest/images/chicken/red-chick.webp'
// import imgOne3 from './assest/images/chicken/g-chicken.webp'
import imgOne4 from './assest/images/chicken/e-briyani.webp'
import imgOne5 from './assest/images/chicken/eg-briyani.webp'
import imgOne6 from './assest/images/chicken/pep-chick.webp'
import imgOne7 from './assest/images/chicken/cobiran.webp'
import imgOne8 from './assest/images/chicken/chick-briyani.webp'
const data={
    products:[
        {
            id:1 ,
            img:imgOne,
            title:"Chicken-Gravy",
            desc:"chicken frys",
            price:100,
        },
        {
            id:2,
            img:imgOne1,
            title:"Chettinad Chicken",
            desc:"Food-2",
            price:127,
        },
        {
            id:3,
            img:imgOne2,
            title:"Red Onion Chicken",
            desc:"Food-3",
            price:89,
        },
        {
            id: 4,
            img:imgOne4,
            title:"Green Chilli (Gravy)",
            desc:"Food -4",
            price:90,
        },
        {
            id: 5,
            img:imgOne5,
            title:"Empty Briyani",
            desc:"Food -4",
            price:70,
        },
        {
            id: 6,
            img:imgOne6,
            title:"Egg Briyani",
            desc:"Food -4",
            price:110,
        },
        {
            id: 7,
            img:imgOne7,
            title:"Coriander Chicken",
            desc:"Food -4",
            price:120,
        },
        {
            id: 8,
            img:imgOne8,
            title:"Green Chilli (Gravy)",
            desc:"Food -4",
            price:90,
        },
       
    ]
}
export default data;