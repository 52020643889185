import React from 'react'
import Logo from '../assest/images/resr.png'
const Footer = () => {
    return (
        <>
            <div className="foote bg-dark ">
                <div className="container">
                    <div className="row align-items-center fott py-5 ">
                        <div className=" col-lg-3 col-xl-3">
                            <img src={Logo} alt="" className="w-100"/>

                        </div>
                        <div className="col-lg-3 col-xl-3 fott d-none d-sm-none d-lg-block d-xl-block">
                            <ul className="list">
                                <li>Veg</li>
                                <li>Non-Veg</li>
                                <li>Ice-creams</li>
                                <li>Sea-Foods</li>
                                <li>Meals</li>
                               
                            </ul>
                          
                        </div>
                        <div className="col-lg-3 col-xl-3 fott d-none d-sm-none d-lg-block d-xl-block">
                            <ul className="list">
                                <li>Home</li>
                                <li>Services</li>
                                <li>About</li>
                                <li>Contact</li>
                                
                               
                            </ul>
                          
                        </div>
                        <div className="col-lg-3 col-xl-3 fott text-white social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-square-instagram"></i>
                        <i class="fa-brands fa-linkedin"></i>

                        </div>
                    </div>


                </div>

            </div>

            <footer className=" text-white text-center p-3">copyright 	&#169; <a href="">newrestaurant.gmail.com</a> </footer>
        </>
    )
}

export default Footer
