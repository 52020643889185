import React from 'react'
import './Pages.css'
import Banner from '../assest/images/burger-1.png'
import Bread from '../assest/images/bread -1.png'
import Breads from '../assest/images/breadm.webp'
import Footer from './Footer'
const About = () => {
    return (
        <>
           <div className="about">
               <div className="banner">
                   <img src={Banner} alt=""/>
               </div>
           </div>
           <section className="bread">
               <div className="container">
                   <div className="row">
                       <div className="col-md-6 col-lg-6  ">
                           <img src={Bread} alt="" className="bre"/>
                       </div>
                       <div className="col-md-6 col-lg-6">
                           <p className="fs-5 mt-5 px-5">
                           - White bread is made up of bleached flour that makes it lighter in color whereas brown bread is made of whole wheat flour and white flour.
- Brown bread is more nutritious and healthier than white bread.
- White bread is bleached with chemicals through an artificial process unlike brown bread which mostly contains natural elements.
- White bread is softer and tastier than brown bread.
- White bread has more calorie content than brown bread. 100 grams of white bread contains 361 calories whereas brown bread has 293 calories per 100 grams.
                           </p>
                       </div>
                   </div>
               </div>
           </section>

           <section className="bread-1">
               <div className="container">
                   <div className="row">
                     
                       <div className="col-md-6 col-lg-6">
                           <p className="fs-5 px-5">
                           The most delightful things that we encounter in our life are often very simple, like good food. It doesn't have to be exotic with a complex recipe, to be delicious. There is nothing like the satisfaction you get from a mug of hot coffee on a rainy day and bread toast with a hot bowl of soup. A humble slice of bread has saved us from our daily lunch-box dilemmas, it is a versatile food item that is an ultimate meal companion. It started with a simple combination of bread and water which has now modified into various recipes over the years. Every culture uses the bread differently but it is still something we can't say no to.
                                                    </p>
                       </div>
                       <div className="col-md-6 col-lg-6 br-img ">
                           <img src={Breads} alt="" className="rounded"/>
                       </div>
                   </div>
               </div>
           </section>
           <Footer/>
        </>
    )
}

export default About
