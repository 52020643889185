
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Contact from './Components/Contact'
import Home from './Components/Home'
import Menu from './Components/Menu' 
import About from './Components/About';
import Services from './Components/Services';
import TopNavbar from './Components/TopNavbar';
import Cart from './Components/cards/Cart'
import { CartProvider } from 'react-use-cart';
import Cards from './Components/cards/Cards';


function App() {
  return (
    <div className="App">
     <>
     <BrowserRouter>
     <CartProvider>
     <TopNavbar/>
     <Menu/>
     
     <Routes>
       
       <Route path="/" element={ <Home/> }/>
       <Route path="/cart" element={<Cart/>}/>
       <Route path="/cards" element={<Cards/>}/>
       <Route path="/services" element={ <Services/> }/>
       <Route path="/about" element={ <About/> }/>
       <Route path='/contact' element= {<Contact/>}/>
       

      
     </Routes>
     </CartProvider>
     </BrowserRouter>
     </>
    </div>
  );
}

export default App;
