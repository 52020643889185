import React from 'react'
import Cards from './cards/Cards'
import data from '../backend'
import Footer from './Footer'





const Home = () => {
  

  
    return (
        <>
           <div className="bg-images">
               <div className="container ">
                   <div className="banner-text">
                       <h2 className="text-primary banner-head">Restaurant</h2>
                  <h1 className="banner-sub">Healthy & Tasty Foods</h1>
                 
                   </div>
                  
               </div>
           </div>
            <div className="container-fluid">
                <h3 className="text-center mt-5 text-uppercase">Shop Pages</h3>
               
                  
            </div>
            <div className="container">
                <div className="row">
                {data.products.map((item,index)=>{
                            return(
                            
              <Cards 
              img={item.img}
               title={item.title}
                desc={item.desc} price={item.price} 
                item={item} 
                key={index}/>
                                                    

                            )
                        })}
                        
                   

                </div>
            </div>
                        
                        <Footer/>
      
        </>
    )
}

export default Home
