import React from 'react'
import{Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { useCart } from 'react-use-cart'



const TopNavbar = () => {
    const{
        totalUniqueItems
    }=useCart();
     return (
        <>
     <div className="topnavbar" bg="dark" variant="dark d-flex align-items-center ">
         <div className="icon d-none d-sm-none d-md-none d-lg-block d-xl-block">
         <a href="tel:9865597859"><i class="fa-solid fa-phone"></i><span className="">123-456-7890 </span></a>
<a href="mail:srvinith6@gmail.com"><i class="fa-solid fa-envelope"></i><span>newrestaurant.gmail.com</span></a>
         </div>
         <div className="icon-mob d-block d-sm-block d-md-block d-lg-none d-xl-none d-flex align-items-center">
         <a href="tel:9865597859"><i class="fa-solid fa-phone text-white fs-1 me-5"></i></a>
<a href="mail:srvinith6@gmail.com"><i class="fa-solid fa-envelope text-white fs-1 me-5"></i></a>
         </div>
         <div className="icon user">
         <a href=""><i class="fa-solid fa-user "></i><span></span></a>
    <Nav.Link as={Link} to='/cart' className='nav-item'><i class="fa-solid fa-cart-shopping "><span className="count">{totalUniqueItems}</span></i></Nav.Link>
         </div>
         
         </div>      
        </>
    )
}

export default TopNavbar
